//@ts-check
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { history, utilService } from '../../_helpers';
import { alertActions, proofService, apiAccessActions, PROOF_OPEN_MODE, apiAccessService } from '..';
import { useTheme } from '../App/Theme/ThemeContext';
import { useQuery } from '../../_helpers/hooks/useQuery';
import { ProofDocumentEditor } from './DocumentAnnotation/ProofDocumentEditor';
import { ProofVideoEditor } from '../Proof/VideoAnnotation/ProofVideoEditor';
import { GlobalContext, withAppWrapperContext } from '../../AppWrapper';
const ProofShareEditRequest = (props) => {
    const [message, setMessage] = useState('Loading...');
    const { theme, setTheme, setCustomTheme } = useTheme();
    const [proofVersion, setProofVersion] = useState();
    const query = useQuery();
    const { updateUser } = useContext(GlobalContext);

    useEffect(() => {
        (async function () {
            const hideNavigation = query.get('hideNavigation');
            const hideTranscription = query.get('hideTranscription');

            props.dispatch(alertActions.disableNavActions(true));
            var token = props.match && props.match.params ? props.match.params.token : null;

            var darkMode = query.get('darkMode');
            var mentionURL = query.get('mentionWebhookURL');

            try {
                mentionURL = mentionURL ? decodeURIComponent(mentionURL) : null;
            } catch (error) {
                mentionURL = null;
            }

            updatePlayerColors(darkMode && darkMode?.toLocaleLowerCase() === 'true' ? true : null);

            var proofId = props.match && props.match.params ? props.match.params.proofId : null;
            var versionId = props.match && props.match.params ? props.match.params.versionId : null;
            if (!proofId || !versionId) {
                setMessage('Bad parameters !');
                return;
            }
            await apiAccessService.validateAndProcessToken(token, updateUser);
                        
            let proofVersion = await proofService.getProofVersion(proofId, versionId);
            setProofVersion(proofVersion);
            if (proofVersion && !proofVersion.id) {
                setMessage('File not found !');
                return;
            }
            let baseRoute = '/share/proof/editor';
                    
            let path = `${baseRoute}/${proofId}/${versionId}`;

            history.replace({
                pathname: path,
                state: { token },
            });
        })();
    }, []);

    const updatePlayerColors = (isDarkMode) => {
        if (!isDarkMode) {
            setTheme('light');

            const backgroundColor = utilService.getQueryStringValue(
                'backgroundColor',
                window.location.search,
                window.location.href
            );
            const primaryColor = utilService.getQueryStringValue(
                'primaryColor',
                window.location.search,
                window.location.href
            );
            const primaryTextColor = utilService.getQueryStringValue(
                'primaryTextColor',
                window.location.search,
                window.location.href
            );
            const primaryAccentColor = utilService.getQueryStringValue(
                'primaryAccentColor',
                window.location.search,
                window.location.href
            );

            let updatedPlayerColors = {
                backgroundColor: backgroundColor ? `#${backgroundColor}` : theme.backgroundColor,
                primaryColor: primaryColor ? `#${primaryColor}` : theme.primaryColor,
                primaryTextColor: primaryTextColor
                    ? `#${primaryTextColor}`
                    : theme.primaryTextColor,
                primaryAccentColor: primaryAccentColor
                    ? `#${primaryAccentColor}`
                    : theme.primaryAccentColor
            };

            var themeReq = {
                primaryColor: updatedPlayerColors.primaryAccentColor,
                primaryBackgroundColor: updatedPlayerColors.backgroundColor,
                secondaryBackgroundColor: updatedPlayerColors.primaryColor,
                primaryTextColor: updatedPlayerColors.primaryTextColor,
                secondaryTextColor: updatedPlayerColors.primaryTextColor,
                iconTextColor: updatedPlayerColors.primaryTextColor
            };

            setCustomTheme(themeReq);
        } else {
            setTheme('dark');
        }
    };

    return (
        <div
            className="mrnda-document-editor-body"
            style={{}}>
            {/* {proofVersion === undefined ? (
                <h1>{message}</h1>
            ) : (
                <>
                    {proofVersion.pages && (
                        
                        proofVersion.mediaType === 2 || proofVersion.mediaType === 4
                    ?                                  
                    <ProofVideoEditor                        
                            mode = { PROOF_OPEN_MODE.Editor }                          
                            proofVersion={proofVersion}                            
                            showThumbnails={true}                                                        
                            setEditorRef={() => {}}                                                        
                        />
                    : 


                        <ProofDocumentEditor                        
                            mode = { PROOF_OPEN_MODE.Editor }                          
                            proofVersion={proofVersion}                            
                            showThumbnails={true}                                                        
                            setEditorRef={() => {}}                                                        
                        />
                    )}
                </>
            )} */}
        </div>
    );
};

function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert
    };
}

const connectedRegisterPage = connect(mapStateToProps)(withRouter(ProofShareEditRequest));
export { connectedRegisterPage as ProofShareEditRequest };
