import { store } from './';
export const authorizationService = {
    isUserPermitted
};
function isUserPermitted(module, action) {
    try {
        const state = store.getState();
        const { authentication } = state;
        var user = authentication?.user;
        if (!!!user || typeof user == 'string') {
            user = JSON.parse(localStorage.getItem('user'));
        }

        if (user?.authData?.token) {
            var existedModule =
                user.rolePermission && user.rolePermission.find((x) => x.key === module);
            var exists =
                existedModule &&
                existedModule.actions &&
                existedModule.actions.some((y) => y.key == action);
            return exists;
        } else {
            throw new Error('User session does not exist');
        }
    } catch (error) {
        return false;
    }
}
