//@ts-check
import React, { useContext, useEffect, useState } from 'react';
import { apiAccessService, userActions, userService } from './components';
import { history, store } from './_helpers';

const GlobalContext = React.createContext({
    user: {},
    updateUser: (a, b) => {},
    updateUserPreferences: (a, b) => {}
});

const withAppWrapperContext = (Component) => {
    return function WrappedComponent(props) {
        const globalContext = useContext(GlobalContext);
        return <Component {...props} globalContext={globalContext} />;
    };
};

const AppWrapper = ({ children }) => {
    const [userData, setUserData] = useState({});

    useEffect(() => {
        runOnMount();
    }, []);

    const handleUpdateUserPreferences = (inComingUserPreferences, persist = true) => {
        const user = { ...userData, authData: userData?.authData || {} };
        user.authData.userPreference = { ...inComingUserPreferences };
        setUserData(user);
        persist && localStorage.setItem('user', JSON.stringify(user));
    };

    const handleUpdateUser = (incomingUserData = {}, persist = true) => {
        const user = { ...userData, ...incomingUserData };
        setUserData(user);
        persist && localStorage.setItem('user', JSON.stringify(user));
    };

    const runOnMount = async () => {
        try {
            const userFromLocalStorage = JSON.parse(localStorage.getItem('user'));
            if (userFromLocalStorage && userFromLocalStorage?.authData?.token) {
                await apiAccessService.validateAndProcessToken(
                    userFromLocalStorage?.authData?.token,
                    setUserData
                );
            } else {
                throw new Error('User session does not exist');
            }
        } catch (error) {
            // store.dispatch(userActions.logout());
            // var { from } = { from: { pathname: '/' } };
            // history.push({ ...from, hasBackRoute: false, isLogoutReloading: true });
            console.log('Error in runOnMount', error);
        }
    };

    return (
        <>
            <GlobalContext.Provider
                value={{
                    user: userData,
                    updateUser: handleUpdateUser,
                    updateUserPreferences: handleUpdateUserPreferences
                }}>
                {children}
            </GlobalContext.Provider>
        </>
    );
};

export { AppWrapper, GlobalContext, withAppWrapperContext };
