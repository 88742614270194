import { authHeader, handleResponse, config, store } from '../../_helpers';
import { userActions } from '../User';
import { apiAccessActions } from './apiaccess.action';

export const apiAccessService = {
    getAll,
    add,
    delete: _delete,
    validateToken,
    isNameUnique,
    validateAndProcessToken
};

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };
    return fetch(`${config.apiUrl}/apiaccess/getall`, requestOptions).then(handleResponse);
}

function add(param) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(param)
    };
    return fetch(`${config.apiUrl}/apiaccess/add`, requestOptions).then(handleResponse);
}

function _delete(param) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };
    return fetch(`${config.apiUrl}/apiaccess/delete/${param}`, requestOptions).then(handleResponse);
}

async function isNameUnique(requestObject) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(requestObject)
    };

    return fetch(`${config.apiUrl}/apiaccess/isunique`, requestOptions).then(handleResponse);
}

function validateToken(param) {
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + param.tokenValue,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(param)
    };
    return fetch(`${config.apiUrl}/apiaccess/validatetoken`, requestOptions).then(handleResponse);
}

async function validateAndProcessToken(token, setUserData) {
    try {
        var user = await store.dispatch(apiAccessActions.validateToken(token));
        if(!user?.authData?.token) {
            throw new Error('User session does not exist');
        }
        if (user?.authData?.token) {
            setUserData && setUserData(user);
            return user;
        } else {
            throw new Error('User session does not exist');
        }
    } catch (error) {
        store.dispatch(userActions.logout());
        return false;
    }
}
