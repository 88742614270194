//@ts-check

import React from 'react';
import ChevronUp from '../../../_shared/Icon/ChevronUp';
import ChevronDown from '../../../_shared/Icon/ChevronDown';
import BackwardIcon from '../../../_shared/Icon/BackwardIcon';
import PlayIcon from '../../../_shared/Icon/PlayIcon';
import PauseIcon from '../../../_shared/Icon/PauseIcon';
import ForwardIcon from '../../../_shared/Icon/ForwardIcon';
import AudioMutedIcon from '../../../_shared/Icon/AudioMutedIcon';
import AudioQuietIcon from '../../../_shared/Icon/AudioQuietIcon';
import AudioLoudIcon from '../../../_shared/Icon/AudioLoudIcon';
import cx from 'classnames';
import { videoAnnotationConstants } from '../Context/videoAnnotation.constants';
import { useScreenSizes } from '../../../responsive/useScreenSizes';
import TimelineCollapsedIcon from '../../../_shared/Icon/TimelineCollapsedIcon';
import TimelineExpandedIcon from '../../../_shared/Icon/TimelineExpandedIcon';

const VideoDurationLabel = ({ getTime }) => {
    return (
        <div style={{ fontSize: '14px', color: 'var(--secondary-text-color)' }}> {getTime()} </div>
    );
};

function VideoPlayerControlButtons({
    getTimers,
    volume,
    onVolumeChange,
    isPaused,
    onPlayPauseToggle,
    timeMode,
    onMetadataConfigureShow,
    onStepChange,
    handleOnTimeModChange,
    getElapsedTime,
    getTotalTime,
    isTranscriptionOnlyMode = false,
    userPreferences,
    handleUpdateUserPreferences
}) {
    const { isMobile } = useScreenSizes();
    const { TIME, TIME_FRAME, TIME_CODE } = videoAnnotationConstants.TIME_MODE;

    return (
        <div
            style={{ margin: 0, padding: '10px 12px' }}
            className={cx('mrnda-video-editor-area-controls-buttons-wrapper', {
                'mrnda-video-editor-area-controls-buttons-wrapper--mobile': isMobile
            })}>
            <div className="mrnda-video-editor-area-controls-button--timers">
                {isMobile ? (
                    <VideoDurationLabel getTime={getElapsedTime} />
                ) : (
                    <>
                        {!isTranscriptionOnlyMode && (
                            <div
                                style={{
                                    display: 'flex',
                                    cursor: 'pointer',
                                    marginRight: '10px',
                                    backgroundColor: 'var(--primary-background-color)',
                                    padding: '4px 10px 8px 10px',
                                    borderRadius: '6px'
                                }}>
                                <TimelineCollapsedIcon
                                    title="Collapsed Timeline"
                                    style={{
                                        marginRight: '10px',
                                        opacity: userPreferences?.expandedTimeline ? 0.3 : 1
                                    }}
                                    onClick={() => {
                                        handleUpdateUserPreferences({
                                            expandedTimeline: false
                                        });
                                    }}
                                />
                                <TimelineExpandedIcon
                                    title="Expanded Timeline"
                                    style={{
                                        opacity: userPreferences?.expandedTimeline ? 1 : 0.3
                                    }}
                                    onClick={() => {
                                        handleUpdateUserPreferences({
                                            ...userPreferences,
                                            expandedTimeline: true
                                        });
                                    }}
                                />
                            </div>
                        )}
                        <div className="btn-group">
                            <div
                                style={{ display: 'flex', cursor: 'pointer' }}
                                className=""
                                data-toggle="dropdown">
                                {!isMobile && (
                                    <div
                                        style={{
                                            display: 'inline',
                                            marginTop: '-3px',
                                            marginRight: '6px'
                                        }}>
                                        <div style={{}}>
                                            <ChevronUp />
                                        </div>
                                        <div style={{ marginTop: '-14px' }}>
                                            <ChevronDown />
                                        </div>
                                    </div>
                                )}
                                <label
                                    style={{
                                        // color: 'white',
                                        cursor: 'pointer',
                                        fontFamily: 'Consolas',
                                        letterSpacing: '-.7px',
                                        fontSize: '13px',
                                        margin: '1px 0px 0px 0px'
                                    }}>
                                    {getTimers()}
                                </label>
                            </div>
                            <div
                                style={{ width: '50px' }}
                                className="dropdown-menu drop-down-themed">
                                <a
                                    className={cx('dropdown-item', { active: timeMode === TIME })}
                                    onClick={() => handleOnTimeModChange(TIME)}>
                                    Time
                                </a>
                                <a
                                    className={cx('dropdown-item', {
                                        active: timeMode === TIME_FRAME
                                    })}
                                    onClick={() => handleOnTimeModChange(TIME_FRAME)}>
                                    Frame
                                </a>
                                <a
                                    className={cx('dropdown-item', {
                                        active: timeMode === TIME_CODE
                                    })}
                                    onClick={() => handleOnTimeModChange(TIME_CODE)}>
                                    Time Code
                                </a>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div
                className={cx('mrnda-video-editor-area-controls-buttons', {
                    'mrnda-video-editor-area-controls-buttons--mobile': isMobile
                })}
                style={{ display: 'flex' }}>
                <span
                    className={cx('mrnda-video-editor-area-controls-buttons--step', {
                        'mrnda-video-editor-area-controls-buttons--mobile--step': isMobile
                    })}
                    onClick={() => onStepChange(-1)}>
                    <BackwardIcon />
                </span>
                <span
                    className={cx('mrnda-video-editor-area-controls-buttons--play-pause', {
                        'mrnda-video-editor-area-controls-buttons--mobile--play-pause': isMobile
                    })}
                    onClick={() => onPlayPauseToggle()}>
                    {isPaused ? <PlayIcon /> : <PauseIcon />}
                </span>
                <span
                    className={cx('mrnda-video-editor-area-controls-buttons--step', {
                        'mrnda-video-editor-area-controls-buttons--mobile--step': isMobile
                    })}
                    onClick={() => onStepChange(1)}>
                    <ForwardIcon />
                </span>
            </div>
            {isMobile ? (
                <div className="mrnda-video-editor-area-controls-sides">
                    {' '}
                    <VideoDurationLabel getTime={getTotalTime} />{' '}
                </div>
            ) : (
                <div className="mrnda-video-editor-area-controls-sides">
                    <span className="mrnda-video-editor-area-controls-sides--volume-icon">
                        {volume == 0 ? (
                            <AudioMutedIcon />
                        ) : volume < 0.5 ? (
                            <AudioQuietIcon />
                        ) : (
                            <AudioLoudIcon />
                        )}
                    </span>
                    <span>
                        <input
                            type="range"
                            name="volume"
                            className="mrnda-video-editor-area-controls-sides--volume"
                            min="0"
                            max="1"
                            step="0.05"
                            value={volume}
                            onChange={(event) => onVolumeChange(event.currentTarget.value)}
                        />
                    </span>
                    <span
                        className={'mrnda-video-editor-area-controls-sides--settings d-none'}
                        style={{}}
                        title="Configure Metadata"
                        onClick={onMetadataConfigureShow}>
                        <i className="fas fa-sliders-h" />
                    </span>
                </div>
            )}
        </div>
    );
}

export default VideoPlayerControlButtons;
