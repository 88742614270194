//@ts-check
import React from 'react';
import SideCollapseIcon from '../../_shared/Icon/SideCollapseIcon';
import CrossIcon from '../../_shared/Icon/CrossIcon';
import { useTheme } from '../../App/Theme/ThemeContext';

const SidePanelHeader = ({ isCollapsed, hideCloseButton, onCloseClick, onCollapseClick }) => {
    const { theme, themeColors } = useTheme();
    const collapsedStyle = isCollapsed ? { flexDirection: 'column-reverse' } : {};

    return (
        <div
            className="mrnda-video-editor-sidebar__header--actions"
            style={{
                width: isCollapsed ? 'auto' : '100%',
                height: 'auto',
                display: 'flex',
                flexDirection: 'column',
                marginBottom: isCollapsed ? 0 : '24px',
                padding: isCollapsed ? '8px 10px 12px 10px' : '0px',
                margin: isCollapsed ? '0px 8px' : '0px',
                borderRadius: isCollapsed ? '6px' : '0px',
                backgroundColor: isCollapsed ? themeColors?.secondaryBackgroundColor : 'inherit'
            }}>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                    alignItems: 'center',
                    color: themeColors?.primaryTextColor,
                    ...collapsedStyle
                }}>
                <SideCollapseIcon
                    title={isCollapsed ? 'Expand' : 'Collapse'}
                    style={{
                        marginTop: isCollapsed ? '8px' : '0px'
                    }}
                    onClick={onCollapseClick}
                />
                {!hideCloseButton && <CrossIcon onClick={onCloseClick} title={'Close'} />}
            </div>
            {!isCollapsed && (
                <hr
                    style={{
                        width: '104%',
                        position: 'relative',
                        left: '-7px',
                        marginTop: '10px'
                    }}
                />
            )}
        </div>
    );
};

export default SidePanelHeader;
