//@ts-check
import React from 'react';
import { CommentBox } from '../../DocumentAnnotation/Subs/Comments/CommentBox';
import { trimAnnotationObject, switchVideoEditorDirty } from '../VideoAnnotationUtil';
import { withVideoAnnotationConsumer } from '../Context/VideoAnnotationConsumer';
import { videoCommentService } from '../videoComment.service';
import { CommentItem } from '../../DocumentAnnotation/Subs/Comments/CommentItem';
import {
    videoAnnotationConstants,
    initPlayerColors,
    sidebarTabViewMode,
    transcriptionStatus
} from '../Context/videoAnnotation.constants';
import { store, history, utilService, contributorsHelper } from '../../../../_helpers';
import { s3Service } from '../../../_shared';
import { drawingConstants } from '@wunderman-thompson/miranda-drawing';
import { alertActions } from '../../../App';
import { ProofVideoTranscriptionView } from '../../VideoTranscription/Subs/ProofVideoTranscriptionView';
import cx from 'classnames';
import { videoTranscriptionService } from '../../VideoTranscription';
import { connect } from 'react-redux';
import { ProofCompareGlobalModal } from '../../ProofCompare/ProofCompareGlobalModal';
import { ThemeChanger } from '../../../App/Theme/ThemeChanger';
import { readableTime } from '../../VideoAnnotation/VideoAnnotationUtil';
import { CommentSorter } from '../../DocumentAnnotation/Subs/Comments/CommentSorter';
import { CommentListWrapper } from '../../DocumentAnnotation/Subs/Comments/CommentListWrapper';
import { handleOnCancelMergeAnnotation } from '../../annotationBase';
import { AnnotationShareModal } from '../../AnnotationShareModal';
import ProofVersionStatus from '../../ProofViewer/ProofVersionStatus';
import { conditionalClassName } from '../../../responsive/responsiveUtils';
import ProofEditorHeader from './ProofEditorHeader';
import DesktopBreakpoint from '../../../responsive/DesktopBreakpoint';
import PhoneBreakpoint from '../../../responsive/PhoneBreakpoint';
import SidePanelHeader from '../../Common/SidePanelHeader';

const transcriptionPlaceholderStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1
};
class VideoAnnotationComment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showProofCompareModal: false,
            showAllComments: false,
            showShareModal: false
        };
    }

    isAnnotationCurrent(annotation) {
        return this.props.currentFrameNumber === annotation.frameRange[0]
            ? '#715f50'
            : 'transparent';
    }

    async handleOnPostComment(comment, attachments = null, mentions = null) {
        this.props.donotHanldeLiveUpdate.current = true;
        const { videoMetadata, selectedAnnotation: currentAnnotations } = this.props;
        var rangeSelectorValue = [...this.props.rangeSelectorValue];
        const { proofId, proofVersionId, currentTenant } = videoMetadata;

        var commentAddPayload = {
            proofId: proofId,
            proofVersionId: proofVersionId,
            content: comment,
            status: videoAnnotationConstants.COMMENT_STATUS.None,
            attachments: attachments,
            mentions: mentions,
            mentionURL: videoMetadata?.mentionURL,
            frameRange: rangeSelectorValue
        };

        commentAddPayload.annotation = [];

        currentAnnotations.forEach((currentAnnotation) => {
            trimAnnotationObject(currentAnnotation.annotationObject);

            if (
                currentAnnotation.annotationObject.type ===
                drawingConstants.ANNOTATION_TYPE.commentStamp.typeName
            ) {
                currentAnnotation.annotationObject.tintColor = contributorsHelper.convertColorToKey(
                    currentAnnotation.annotationObject.tintColor
                );
            } else {
                currentAnnotation.annotationObject.stroke = contributorsHelper.convertColorToKey(
                    currentAnnotation.annotationObject.stroke
                );
            }

            commentAddPayload.annotation.push({
                annotationType: currentAnnotation.annotationType,
                annotationObject: JSON.stringify(currentAnnotation.annotationObject),
                frameRange: rangeSelectorValue
            });
        });

        let commentResponse = await videoCommentService.addVideoComment(commentAddPayload);
        this.props.setEditAllowedComments([...this.props.editAllowedComments, commentResponse.id]);

        this.processAnnotationsAfterSave(commentResponse, currentAnnotations);

        this.cleanStateAfterSave();

        await this.props.refreshComments();
        // this.props.setSelectedComment(this.props.annotationComments.length - 1);
        this.props.donotHanldeLiveUpdate.current = false;
    }

    cleanStateAfterSave() {
        this.props.setIsCommentAddMode(false);
        this.props.setIsComposite(false);
        this.props.setIsCommentMerge(false);
        this.props.setSelectedAnnotation([]);
        this.props.setCommentText('');
    }
    processAnnotationsAfterSave(commentResponse, currentAnnotations) {
        var totalAdded = commentResponse.annotations.length - currentAnnotations.length;

        currentAnnotations.forEach((element, index) => {
            var targetPage = element.page;
            var responseIndex = totalAdded + index;
            const shapeInfo = { ...element.annotationObject };
            var temporaryShapeId = element.uuid;
            this.props.mainDrawingCanvas.removeObjectById(temporaryShapeId);
            var drawableAnnotation = {
                parent: commentResponse.id,
                createdById: commentResponse.createdById,
                ...commentResponse.annotations[responseIndex]
            };
            this.props.drawSingleAnnotation(targetPage, drawableAnnotation, false, false);
        });
    }

    async handleOnAttachAnnotationWithComment(commentId) {
        this.props.donotHanldeLiveUpdate.current = true;
        const { videoMetadata, selectedAnnotation: currentAnnotations } = this.props;

        var addedAnnotations = [];

        currentAnnotations.forEach((currentAnnotation) => {
            var { commentAddPayload } = this.prepareAnnotations(
                videoMetadata,
                currentAnnotation,
                commentId
            );

            addedAnnotations.push(commentAddPayload);
        });

        let commentResponse = await videoCommentService.addAnnotation(addedAnnotations);

        this.processAnnotationsAfterSave(commentResponse, currentAnnotations);

        this.cleanStateAfterSave();

        await this.props.refreshComments();
        this.props.setSelectedComment(-1);
        this.props.donotHanldeLiveUpdate.current = false;
    }

    prepareAnnotations(documentMetadata, currentAnnotation, commentId) {
        const { proofId, proofVersionId, currentTenant } = documentMetadata;

        const shapeInfo = { ...currentAnnotation.annotationObject };
        trimAnnotationObject(currentAnnotation.annotationObject);

        if (
            currentAnnotation.annotationObject.type ===
            drawingConstants.ANNOTATION_TYPE.commentStamp.typeName
        ) {
            currentAnnotation.annotationObject.tintColor = contributorsHelper.convertColorToKey(
                currentAnnotation.annotationObject.tintColor
            );
        } else {
            currentAnnotation.annotationObject.stroke = contributorsHelper.convertColorToKey(
                currentAnnotation.annotationObject.stroke
            );
        }

        var commentAddPayload = {
            videoCommentId: commentId,
            proofId: proofId,
            proofVersionId: proofVersionId,
            annotationType: currentAnnotation.annotationType,
            annotationObject: JSON.stringify(currentAnnotation.annotationObject),
            frameRange: currentAnnotation.frameRange
        };
        return { commentAddPayload };
    }

    handleOnCancelComment() {
        this.props.selectedAnnotation.forEach((element) => {
            this.props.removeCanvasObjectById(element.uuid);
        });
        this.props.setSelectedAnnotation([]);
        this.props.setIsCommentAddMode(false);
        this.props.setIsComposite(false);
        this.props.setCommentText('');
    }

    handleOnCancelMergeAnnotation() {
        handleOnCancelMergeAnnotation(this.props);
    }

    handleOnCommentClicked(commentItem) {
        if (this.props.initDrawing === true) {
            return;
        }
        if (this.props.selectedComment === commentItem.index) {
            return;
        }
        this.props.setSelectedComment(commentItem.index);
        this.props.seekToTime(commentItem.frameRange[0]);
    }

    deleteAnnotationMarker = (id) => {
        var currentMarkers = [...this.props.markers];
        var markerIndex = currentMarkers.findIndex((x) => x.id === id);
        currentMarkers.splice(markerIndex, 1);
        this.props.setMarkers(currentMarkers);
    };
    async handleOnCommentDelete(comment) {
        this.props.deleteCommentArrowPointFinder(comment.id);
        this.props.releaseCommentSelection();

        this.props.donotHanldeLiveUpdate.current = true;
        await videoCommentService.deleteVideoComment(comment.id);
        comment.annotations.forEach((annn) => {
            this.props.removeCanvasObjectById(annn.id);
        });
        this.deleteAnnotationMarker(comment.id);
        await this.props.refreshComments();
        this.props.donotHanldeLiveUpdate.current = false;

        contributorsHelper.configureContributors(
            this.props.proofVersion,
            this.props.annotationComments,
            this.props.contributors,
            this.props.userId,
            false,
            this.props
        );
    }

    async handleOnCommentSetStatus(statusValue, commentId) {
        var payload = {
            id: commentId,
            status: statusValue
        };
        await videoCommentService.setVideoCommentStatus(payload);
        await this.props.refreshComments();
        this.props.setSelectedComment(-1);
    }

    async handleOnCommentReply(replyItem, attachments = null, mentions = null) {
        var replyAddPayload = {
            videoCommentId: replyItem.commentId,
            content: replyItem.reply,
            attachments: attachments,
            mentions: mentions,
            mentionURL: replyItem?.mentionURL,
            color: contributorsHelper.convertColorToKey(this.props.userAnnotationColor)
        };
        var res = await videoCommentService.addVideoCommentReply(replyAddPayload);
        this.props.setEditAllowedReplies([...this.props.editAllowedReplies, res.id]);
        this.props.setSelectedComment(-1);
        //await this.props.refreshComments();
    }
    async getAttachment(request) {
        var attachment = await videoCommentService.getAttachment(request);
        return attachment;
    }

    switchTabs(mode) {
        this.props.setSidebarTabView(mode);
        setTimeout(() => {
            this.props.reScaleCanvasContainer();
        }, 300);
    }

    async onEnableTranscription() {
        store.dispatch(alertActions.loading(true));
        await videoTranscriptionService.transcribeVideoToText(
            this.props.proofVersion.proofId,
            this.props.proofVersion.id
        );
        this.props.setTranscriptions((prevState) => {
            return { ...prevState, status: transcriptionStatus.IN_PROGRESS };
        });
        store.dispatch(alertActions.loading(false));
    }

    async onAttachmentDelete(attachment) {
        var request = {
            id: attachment.id,
            commentId: attachment.commentId,
            replyId: attachment.replyId
        };
        await videoCommentService.deleteAttachment(request);
        await this.props.refreshComments();
        this.props.setSelectedComment(-1);
    }

    async updateCommentContent(replyAddPayload) {
        var response = await videoCommentService.updateCommentContent(replyAddPayload);
        return response;
    }

    async updateReplyContent(replyAddPayload) {
        var response = await videoCommentService.updateReplyContent(replyAddPayload);
        return response;
    }

    onCloseClick() {
        history.goBack();
    }

    onScrolled(e) {
        if (this.props.selectedComment === -1) {
            return;
        }
        let bounds = e.target.getBoundingClientRect();
        this.props.calculateCommentArrowPoint(this.props.selectedComment, true, bounds);
    }

    findInRange(commentItem) {
        if (this.props.editingCommentId === commentItem.id) {
            return true;
        }
        let insideRange =
            this.props.currentFrameNumber >= commentItem.frameRange[0] &&
            this.props.currentFrameNumber <= commentItem.frameRange[1];
        return insideRange;
    }

    render() {
        const {
            videoMetadata,
            proofVersion,
            setProofVersion,
            transcriptions,
            isCommentAddMode,
            rangeSelectorValue,
            annotationComments,
            currentTimeInSeconds,
            seekToTime,
            sidebarTabView,
            setSidebarTabView,
            reScaleCanvasContainer,
            alert,
            connectionStatus,
            hasNewComment,
            newCommentCount,
            commentsDeleted,
            deletedCommentsBannerClicked,
            deletedCommentCount,
            isProofCompare,
            userPreferences,
            handleUpdateUserPreferences
        } = this.props;
        const { COMPLETED, IN_PROGRESS, NOT_GENERATED } = transcriptionStatus;
        const commentSectionStyle =
            this.props.compactNavbar === true && !isProofCompare
                ? {
                      marginTop: '62px',
                      height: 'calc(100% - 62px)',
                      borderRadius: '8px'
                  }
                : {};

        const showCommentBar = this.props?.isMobile ? true : userPreferences?.showCommentBar;
        return (
            <>
                {!showCommentBar && (
                    <div
                        style={{
                            marginTop: this.props.compactNavbar ? '62px' : '2px'
                        }}>
                        <SidePanelHeader
                            isCollapsed={!userPreferences?.showCommentBar}
                            onCollapseClick={() => {
                                handleUpdateUserPreferences({
                                    showCommentBar: !userPreferences?.showCommentBar
                                });
                            }}
                            hideCloseButton={alert.isHideTopbar}
                            onCloseClick={this.onCloseClick.bind(this)}
                        />
                    </div>
                )}
                <div
                    style={{
                        display: showCommentBar ? 'flex' : 'none',
                        ...commentSectionStyle
                    }}
                    className={conditionalClassName(
                        'mrnda-video-editor-sidebar',
                        this.props.isMobile,
                        'mobile'
                    )}>
                    {!this.props?.isMobile && !isProofCompare && (
                        <SidePanelHeader
                            isCollapsed={!showCommentBar}
                            onCollapseClick={() => {
                                handleUpdateUserPreferences({
                                    showCommentBar: !userPreferences?.showCommentBar
                                });
                            }}
                            hideCloseButton={alert.isHideTopbar}
                            onCloseClick={this.onCloseClick.bind(this)}
                        />
                    )}
                    <div
                        className="mrnda-video-editor-sidebar__body"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1,
                            overflow: 'hidden auto'
                        }}>
                        {!isProofCompare && (
                            <div className="mrnda-video-editor-sidebar__header">
                                <DesktopBreakpoint>
                                    <ProofEditorHeader
                                        proofVersion={proofVersion}
                                        isNavActionsDisabled={this.props.alert.isNavActionsDisabled}
                                        shareEnabled={
                                            this.props.proofSettings.annotationShareEnabled ===
                                                true &&
                                            !this.props.alert.isNavActionsDisabled &&
                                            this.props.alert.isHideTopbar !== true
                                        }
                                        onShareClicked={() =>
                                            this.setState({
                                                showShareModal: true
                                            })
                                        }
                                        handleCompareClicked={() =>
                                            this.setState({
                                                showProofCompareModal: true
                                            })
                                        }
                                        onCloseClick={this.onCloseClick.bind(this)}
                                        hideCloseButton={alert.isHideTopbar}
                                    />

                                    {proofVersion.proofId !== '' &&
                                        proofVersion.proofId !== undefined && (
                                            <div style={{ marginTop: '12px', marginBottom: '4px' }}>
                                                <ProofVersionStatus
                                                    proofVersion={proofVersion}
                                                    updateProofVersion={(update) => {
                                                        setProofVersion(update);
                                                    }}
                                                />
                                            </div>
                                        )}
                                </DesktopBreakpoint>
                                {isCommentAddMode && (
                                    <CommentBoxHOC
                                        frameRange={rangeSelectorValue}
                                        handleOnPostComment={this.handleOnPostComment.bind(this)}
                                        handleOnCancelComment={this.handleOnCancelComment.bind(
                                            this
                                        )}
                                    />
                                )}
                            </div>
                        )}
                        <div
                            style={{
                                opacity: isCommentAddMode ? 0.3 : 1,
                                pointerEvents: isCommentAddMode ? 'none' : 'all',
                                // overflow: 'hidden auto',
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 1
                            }}>
                            {!this.props.isMobile && (
                                <div className="mrnda-tabs mrnda-video-editor-sidebar__tabs">
                                    <div
                                        className={cx({
                                            'mrnda-tabs-themed__item': true,
                                            'mrnda-tabs-themed__item--active':
                                                sidebarTabView === sidebarTabViewMode.COMMENT,
                                            'mrnda-video-editor-sidebar__tabs__item': true
                                        })}
                                        onClick={() => {
                                            this.switchTabs(sidebarTabViewMode.COMMENT);
                                        }}>
                                        Comments
                                        <CommentSorterHOC />
                                    </div>

                                    {!isProofCompare && !this.props.isMobile && (
                                        <>
                                            {!alert.isHideTranscription &&
                                                proofVersion.mediaType === 2 && (
                                                    <div
                                                        className={cx({
                                                            'mrnda-tabs-themed__item': true,
                                                            'mrnda-tabs-themed__item--active':
                                                                sidebarTabView ===
                                                                sidebarTabViewMode.TRANSCRITPION,
                                                            'mrnda-video-editor-sidebar__tabs__item': true
                                                        })}
                                                        onClick={() => {
                                                            this.switchTabs(
                                                                sidebarTabViewMode.TRANSCRITPION
                                                            );
                                                        }}>
                                                        Transcriptions
                                                        {sidebarTabView ===
                                                            sidebarTabViewMode.TRANSCRITPION && (
                                                            <div
                                                                className="mrnda-tabs-themed__item--active__tools"
                                                                title="Copy transcriptions to clipboard">
                                                                <div className="dropdown">
                                                                    <span
                                                                        id="dropdownMenuButton"
                                                                        data-toggle="dropdown"
                                                                        aria-haspopup="true"
                                                                        aria-expanded="false"
                                                                        style={{}}
                                                                        className="threedots__label"
                                                                        data-toggle="dropdown">
                                                                        <i className="fas fa-ellipsis-v"></i>
                                                                    </span>
                                                                    <div
                                                                        className="dropdown-menu drop-down-themed"
                                                                        aria-labelledby="dropdownMenuButton">
                                                                        <div
                                                                            className="dropdown-item rounded-0 drop-down-item-no-transform"
                                                                            onClick={() => {
                                                                                this.copyTranscription(
                                                                                    transcriptions,
                                                                                    true
                                                                                );
                                                                            }}>
                                                                            <a>
                                                                                <span>
                                                                                    Copy with
                                                                                    timestamp
                                                                                </span>
                                                                            </a>
                                                                        </div>
                                                                        <div
                                                                            className="dropdown-item rounded-0 drop-down-item-no-transform"
                                                                            onClick={() => {
                                                                                this.copyTranscription(
                                                                                    transcriptions,
                                                                                    false
                                                                                );
                                                                            }}>
                                                                            <a>
                                                                                <span>
                                                                                    Copy without
                                                                                    timestamp
                                                                                </span>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                        </>
                                    )}
                                </div>
                            )}
                            <div
                                className="mrnda-video-editor-sidebar__comments mrnda-tabs-content  mt-4"
                                style={
                                    this.props.isMobile
                                        ? {
                                              display:
                                                  sidebarTabView === 'comment' ? 'flex' : 'none',
                                              flex: 1,
                                              flexDirection: 'column',
                                              padding: '02px 20px 0 20px'
                                          }
                                        : {
                                              display:
                                                  sidebarTabView === 'comment' ? 'flex' : 'none',
                                              flex: 1,
                                              flexDirection: 'column'
                                          }
                                }
                                onScroll={this.onScrolled.bind(this)}>
                                {hasNewComment && (
                                    <div
                                        className="mrnda-editor-sidebar-new-comment-banner"
                                        onClick={this.props.newCommentBannerClicked.bind(this)}>
                                        <p className="mrnda-editor-sidebar-new-comment-banner__text">
                                            {newCommentCount} new comment
                                            {newCommentCount > 1 ? 's' : ''} available
                                        </p>
                                    </div>
                                )}

                                {commentsDeleted && (
                                    <div
                                        className="mrnda-editor-sidebar-new-comment-banner"
                                        onClick={this.props.deletedCommentsBannerClicked.bind(
                                            this
                                        )}>
                                        <p className="mrnda-editor-sidebar-new-comment-banner__text">
                                            {deletedCommentCount} comment
                                            {deletedCommentCount > 1 ? 's' : ''} deleted
                                        </p>
                                    </div>
                                )}

                                {this.props.annotationComments.length > 0 &&
                                    this.props.annotationComments.filter((c) => this.findInRange(c))
                                        .length === 0 && (
                                        <div style={{ ...transcriptionPlaceholderStyle }}>
                                            {/* <i style={{ fontSize: "40px", marginBottom: "12px" }} className="fas fa-hourglass-half"></i> */}
                                            <span style={{ textAlign: 'center' }}>
                                                There is no annotation in this frame
                                                <br />
                                            </span>
                                        </div>
                                    )}

                                {/* Comments are wrapped with Comment list component to provide a 
                        common area for click target for comments to determine if click event
                        is occurred outside this area to clear comment selection arrow */}
                                <CommentList isVideo={true}>
                                    {annotationComments.map((comment, index) => {
                                        var commentItem = { ...comment, index };
                                        var avatarColor, annotationColor;

                                        if (this.props.contributors !== null) {
                                            avatarColor = utilService.getUserAvatarPreferenceColor(
                                                this.props.contributors,
                                                commentItem.createdById
                                            );
                                            annotationColor =
                                                utilService.getUserAnnotationPreferenceColor(
                                                    this.props.contributors,
                                                    commentItem.createdById
                                                );
                                        }
                                        return (
                                            <CommentItemHOC
                                                key={commentItem.id}
                                                commentItem={commentItem}
                                                metadata={videoMetadata}
                                                onCommentReply={this.handleOnCommentReply.bind(
                                                    this
                                                )}
                                                onCommentDelete={this.handleOnCommentDelete.bind(
                                                    this
                                                )}
                                                onCommentSetStatus={this.handleOnCommentSetStatus.bind(
                                                    this
                                                )}
                                                onCommentClicked={this.handleOnCommentClicked.bind(
                                                    this
                                                )}
                                                getAttachment={this.getAttachment.bind(this)}
                                                onAttachmentDelete={this.onAttachmentDelete.bind(
                                                    this
                                                )}
                                                updateCommentContent={this.updateCommentContent.bind(
                                                    this
                                                )}
                                                updateReplyContent={this.updateReplyContent.bind(
                                                    this
                                                )}
                                                onAnnotationMerge={this.handleOnAttachAnnotationWithComment.bind(
                                                    this
                                                )}
                                                cancelMergeAnnotation={this.handleOnCancelMergeAnnotation.bind(
                                                    this
                                                )}
                                                avatarColor={avatarColor}
                                                annotationColor={annotationColor}
                                                isVisible={
                                                    this.findInRange(comment) &&
                                                    this.props.videoPlayer.paused
                                                }
                                                isGroupSelected={
                                                    this.props.currentFrameNumber ===
                                                    comment.frameRange[0]
                                                }
                                                isSelected={this.props.selectedComment === index}
                                                side={this.props.side}
                                                isProofCompare={isProofCompare}
                                            />
                                        );
                                    })}
                                </CommentList>
                            </div>
                            <div
                                className={'mrnda-tabs-content mt-2'}
                                style={{
                                    display: sidebarTabView === 'transcription' ? 'flex' : 'none',
                                    overflow: 'hidden auto',
                                    // justifyContent: 'center',
                                    flexDirection: 'column',
                                    // alignItems: 'center',
                                    flex: 1
                                }}>
                                <ProofVideoTranscriptionView
                                    transcriptions={transcriptions.transcribeData || []}
                                    transcritionStatus={transcriptions.status}
                                    currentTimeInSeconds={currentTimeInSeconds}
                                    componentColors={initPlayerColors}
                                    onItemClick={(item) => {
                                        let frameNumber =
                                            item[0] * proofVersion?.fileMetadata?.frameRate;
                                        seekToTime(frameNumber);
                                    }}
                                    onEnableTranscription={this.onEnableTranscription.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                    {this.state.showProofCompareModal && (
                        <ProofCompareGlobalModal
                            show={this.state.showProofCompareModal}
                            proofId={proofVersion.proofId}
                            currentDirectoryNodeId={proofVersion.directoryNodeId}
                            proofObject={{
                                currentVersionId: proofVersion.id,
                                currentVersionProofId: proofVersion.proofId
                            }}
                            onHide={() =>
                                this.setState({
                                    showProofCompareModal: false
                                })
                            }
                        />
                    )}
                    {this.props.proofSettings.annotationShareEnabled &&
                        this.state.showShareModal && (
                            <AnnotationShareModal
                                show={this.state.showShareModal}
                                onHide={() => this.setState({ showShareModal: false })}
                                proofId={this.props.proofVersion.proofId}
                                proofVersion={this.props.proofVersion}
                                proofSettings={this.props.proofSettings}
                                onClose={() => {}}
                            />
                        )}
                </div>
            </>
        );
    }

    copyTranscription(transcriptions, withTime) {
        var text = '';
        let data = transcriptions.transcribeData;

        if (data === undefined) {
            return;
        }

        data.forEach((element, index) => {
            let readableTimeText = `${readableTime(element.timeRange[0])} - ${readableTime(
                element.timeRange[1]
            )} `;
            text += `${index === 0 ? '' : '\n'}${withTime === true ? readableTimeText : ''}${
                element.value
            }`;
        });
        console.log(text);
        if (text === '') {
            return;
        }

        var textField = document.createElement('textarea');
        textField.innerHTML = text;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();

        store.dispatch(alertActions.success('Transcription copied to clipboard!'));
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert
    };
}
const hoc = connect(mapStateToProps)(withVideoAnnotationConsumer(VideoAnnotationComment));
const CommentItemHOC = withVideoAnnotationConsumer(CommentItem);
const CommentBoxHOC = withVideoAnnotationConsumer(CommentBox);
const CommentSorterHOC = withVideoAnnotationConsumer(CommentSorter);
const CommentList = withVideoAnnotationConsumer(CommentListWrapper);

export { hoc as VideoAnnotationComment };
