import React from 'react';
import { Prompt, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { history, utilService } from '../../_helpers';
import { alertActions, proofService, ConfirmationModal, apiAccessActions, apiAccessService } from '../../components';
import { withAppWrapperContext } from '../../AppWrapper';

class ProofSummaryRequest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            proofVersions: {},
            showExitConfirmModal: false,
            isEditorDirty: false,
            nextLocation: {},
            token: null,
            message: 'Loading...'
        };
        this.currentPathname = null;
        this.currentSearch = null;
    }
    async componentDidMount() {
        const hideNavigation = utilService.getQueryStringValue(
            'hideNavigation',
            window.location.search,
            window.location.href
        );

        if (hideNavigation.toLocaleLowerCase() === 'true') {
            this.props.dispatch(alertActions.hideTopbar(true));
        }
        
        var token = utilService.getQueryStringValue(
            'token',
            window.location.search,
            window.location.href,
        );
        var proofId =
            this.props.match && this.props.match.params ? this.props.match.params.proofid : null;
        var versionId =
            this.props.match && this.props.match.params ? this.props.match.params.versionid : null;
        if (!proofId || !versionId) {
            this.setState({
                message: 'Bad parameters !'
            });
            return;
        }

        await apiAccessService.validateAndProcessToken(token, this.props?.globalContext?.updateUser);

        let proofVersion = await proofService.getProofVersion(proofId, versionId);
        if(proofVersion && !proofVersion.id){
            this.setState({
                message: 'File not found !'
            })
            return;
        }
        // let baseRoute =
        // proofVersion.mediaType === 2 ? '/proof/videoreport' : '/proof/report';
        let baseRoute = '/proof/annotationreport' ;
        history.replace({
            pathname: `${baseRoute}/${proofId}/${versionId}`,
            data: proofVersion,
        });
    }
    render() {
        return (
            <div
            className="mrnda-main-content d-flex justify-content-center align-items-center"
            style={{}}>
                <h1>{this.state.message}</h1>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert,
    };
}

const connected = connect(mapStateToProps)(withRouter(ProofSummaryRequest));
const connectedWithAppContext = withAppWrapperContext(connected);
export { connectedWithAppContext as ProofSummaryRequest };
